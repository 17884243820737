import { FC, ReactNode } from "react";
import { Divider } from "antd";
import Image from "@/components/image";
import Link from "@/components/link";
import styles from "./index.module.css";
import search from "@/public/assets/images/searchIcon.png";
import lmdt from "@/public/assets/images/icon2.png";
import gjzx from "@/public/assets/images/icon1.png";
import Logo from "../Logo";

interface HeaderProps {
    loginBtnContent: ReactNode;
    children?: ReactNode;
}
/**
 * @description: 页面头部
 * @param {*} param1
 * @return {*}
 */
const Header: FC<HeaderProps> = ({ loginBtnContent, children }) => {
    return (
        <header id="header" className={styles.PageHead__wrapper} aria-label="主导航">
            <div className={styles.PageHead}>
                <Link href="/" target='_self' aria-label="中国罕见病综合云服务平台logo" >
                    <Logo />
                </Link>
                <div className={styles.PageHead_suffix}>
                    <Link href="/search" aria-label="搜索">
                        <Image
                            src={search}
                            alt="搜索"
                            className={styles.icon}
                        />
                    </Link>

                    <Link href="https://app.chard.org.cn/" target="_blank" aria-label="跳转中国罕见病综合云服务平台" role='button'>
                        <div className={styles.link_btn}>
                            <Image
                                src={lmdt}
                                alt="中国罕见病综合云服务平台"
                                className="iconImage"
                            />
                            <span>中国罕见病综合云服务平台</span>
                        </div>
                    </Link>

                    <Link href="https://upwards.chard.org.cn/" target="_blank" aria-label="跳转财政部项目管理平台" role='button'>
                        <div className={styles.link_btn}>
                            <Image
                                src={gjzx}
                                alt="财政部项目管理平台"
                                className="iconImage"
                            />
                            <span>财政部项目管理平台</span>
                        </div>
                    </Link>
                    <Divider type="vertical" />
                    {loginBtnContent}
                </div>
            </div>
            {children}
        </header>
    );
};

export default Header;