import { FC } from 'react';
import logo from "@/public/assets/images/logo01.png";
import logo2 from "@/public/assets/images/logo02.png";
import logo3 from "@/public/assets/images/logo03.png";

// ========================= 组件 =========================
import { Divider, Space } from 'antd';
import Image from '@/components/image';

// ========================= 数据交互 =========================

// ========================= 全局数据 =========================

// ========================= 常量 =========================

// ========================= 工具 =========================

// ========================= 样式 =========================
import styles from './index.module.css';

// ========================= 变量 =========================

// ========================= 类型 =========================

interface LogoProps { }

const Logo: FC<LogoProps> = () => {
    return (
        <div className={styles.PageHead__logo}>
            <Space split={<Divider type="vertical" />}>
                <Image
                    src={logo}
                    alt="中国罕见病综合云服务平台"
                    className={styles.logo}
                />
                <Image
                    src={logo2}
                    alt="中国罕见病综合云服务平台"
                    className={styles.logo}
                />
                <Image
                    src={logo3}
                    alt="中国罕见病综合云服务平台"
                    className={styles.logo}
                />
            </Space>
        </div>
    );
}
export default Logo;